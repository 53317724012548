#startup
{
    box-sizing: border-box;
    margin: 0;
    margin-top: 100px;
    padding: 0;
    background-color: black;
}

.title{
    font-size: 96px;
    text-align: center;
    background-image: linear-gradient(#25A8E0, #4936D8);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-container{
    width: 96%;
    margin-left: 2%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    background-color: black;
    color: white;
}

.startup_left
{
width: 250px;
height: 250px;
top: 20px;
border: 1px solid black;
border-radius: 50%;
background: #15141E;
margin-left: 20px;
margin-right: 20px;
text-align: right;
}

.startup_right
{
width: 250px;
height: 250px;
border: 1px solid black;
border-radius: 50%;
margin-left: 20px;
margin-right: 20px;
}

.card-text{
    color: white;
    text-align: left;
}

.card-title{
    color: #25A8E0;
    text-align: left;
}

.col-md-4 .img_space_left{
    text-align: right;
}

.img_space_right{
    text-align:start;
}

.search{
    background: #2C2C2C;
    border-radius: 8px;
    color: rgb(44,44,44,1);
    width: 450px;
    height: 65px;
    margin-left: calc(50% - 225px);
}

.seacrhText{
    width: 73px;
    height: 18px;
    left: 665px;
    top: 386px;
    font-family: 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
}

.btn-social{
    color: white;
    font-size: 20px;
}

@media screen and (max-width: 550px) {
    .img_space_left{
        text-align: center;
    }
    .img_space_right{
        text-align:center;
    }
    .right-body{
        flex-direction: column;
    }
}