.mobile {
  /* display: flex;
    justify-content: center; */
  /* align-items: center; */
  text-align: center;
}
.mobile_title {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  /* text-align: center; */
  letter-spacing: 0.3px;
  color: #ffffff;
}

.mobile_desc {
  margin: auto;
  /* width: 1100px; */
  /* padding: ; */
}

.mobile_desc p {
  font-style: normal;
  font-size: 24px;
  text-align: center;
  color: #c4c4c4;
  letter-spacing: 0.3px;
}

.bg {
  background-image: url("../img/Ellipse\ 191.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 800px;
}

.mobile_sphere {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("../img/Ellipse\ 189.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 750px;
}
.back-grd {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
