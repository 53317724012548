.cards{
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    background-color: black;
}
.speaker{
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: black;
}
.cards_title{
    font-family: SF Pro Display;
    font-style: Bold;
    font-size: 48px;
    text-align: center;
    color: white;
    margin-bottom: 100px;
    justify-content: center;
}
.card{
    background-color: black;
}
.card_bg{
    background: linear-gradient(96.4deg, #25A8E0 0%, #4936D8 101.17%);
    border-radius: 24px 0px;
    margin-top: -20px;
    z-index: 1;
}
.card_img{
    border-radius: 24px 0px;
}
.card_name{
    font-family: SF Pro Display;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: white;
}
.card_designation{
    font-family: SF Pro Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: white;
}
@media (max-width: 480px) {
.card_margin {
    margin-right: 0;
    margin-top: 30px;
 }
}