

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    justify-content: center;
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY.styles_expanded__3elPy .styles_icon-wrapper__2cftw {
    transform: rotate(
-45deg
);
color: #25A8E0;
padding: 22px;
}
.styles_faq-row-wrapper__3vA1D h2{
    margin-bottom: 60px;
    letter-spacing: 0.3px;

}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c 
{
    border-bottom: 0.5px solid #AAAAAA;
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    text-align: start;
    padding: 20px 0;
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw{
    padding: 18px;
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
    text-align: left;
}
.styles_row-title__1YiiY .styles_row-title-text__1MuhU
{
    
    padding: 15px 0;
}
.styles_row-title__1YiiY .styles_row-title-text__1MuhU:active {
    color: #25A8E0;
}

.row-content-text{
    height: 190px;
    
}

.faq-space{
    margin-bottom: 15vh;
}