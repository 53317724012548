.title-content{
    position: absolute;
width: 428px;
height: 43px;
left: 162px;
top: 185px;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 43px;
display: flex;
align-items: center;
text-transform: capitalize;

color: #25A8E0;
}
.header{
    position: absolute;
    width: 532px;
    height: 214px;
    left: 160px;
    top: 254px;
    text-align: left;
    
    font-style: normal;
    font-weight: bold;
    font-size: 51px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.intro-img{
position: absolute;
width: 555px;
    height: 715px;
    left: 732px;
    top: 216px;

}
.intro-img img{
    border-radius: 24px 0px 0px 0px;
}
.event-description{
    position: absolute;
    width: 810px;
    height: 74px;
    left: 162px;
    top: 545px;
background: rgba(215, 243, 255, 0.62);
backdrop-filter: blur(12px);
border-radius: 16px;
opacity: 0.9;
}
.register{
    position: absolute;
    left: 610px;
    top: 13px;
    border-radius: 8px;
}
.icons{

}
.location{
    position: absolute;
    left: 2.5%;
    right: 12.5%;
    top: 16.33%;
    bottom: 1.13%;
}
.location p{
    position: absolute;
width: 197px;
height: 26px;
left: 46px;
top: 14px;


font-style: normal;
font-weight: 600;
font-size: 19px;
line-height: 108.34%;
/* identical to box height, or 26px */

display: flex;
align-items: center;
text-transform: capitalize;

color: #4936D8;
}
.calendar{
    position: absolute;
    left: 32.33%;
    right: 8.33%;
    top: 17.14%;
    bottom: 12.5%;
}
.calendar p{
    position: absolute;
    width: 160px;
    height: 26px;
    left: 46px;
    top: 14px;
    
    
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 108.34%;
    /* identical to box height, or 26px */
    
    display: flex;
    align-items: center;
    text-transform: capitalize;
    
    color: #4936D8;
}
.price{
    position: absolute;
    left: 57.5%;
    right: 12.5%;
    top: 14.89%;
    bottom: 8.33%;
}
.price p{
    position: absolute;
width: 65px;
height: 26px;
left: 46px;
top: 14px;

font-family: SF Pro Display;
font-style: normal;
font-weight: 600;
font-size: 19px;
line-height: 108.34%;
/* identical to box height, or 26px */

display: flex;
align-items: center;
text-transform: capitalize;

color: #4936D8;


}