

.main-div{
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:150px 0;
}

h1.blog-heading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(#25A8E0, #4936D8);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 96px;
}


input.search-box{
    margin-top: 40px;
    width: 600px;
    height: 70px;
    background-color: #2C2C2C;
    padding: 25px 47px;
    border-radius: 12px;
    color: white;
}

.blog-cards{
    width: 100%;
    margin-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.blog-card{
    width: 350px;
    display: flex;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 24px;
    margin-top: 80px;
    height: 550px;
}

.left{
    display: flex;
    flex-direction: column;
    background-color: #15141E;
    color: white;
    padding-bottom: 20px;
    width: 90%;
    
}

.right{
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background-color: #25A8E0;
    color: white;
    min-height: 100%;
    width: 10%;
    padding: 20px;
    border-bottom-right-radius: 24px;
}

.right span{
    margin-left: 20px;
}

.blog-card img{
    width: 100%;
    
    border-top-left-radius: 24px;
}

.blog-text{
    padding: 25px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

span.blog-heading{
    color: #25A8E0;
    font-size: 20px;
    font-weight: 500;
}

.blog-date{
    font-size: 14px;
    color: white;
}

.blog-content{
    margin-top: 18px;
    color: white;
}

