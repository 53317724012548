	/*
  	Flaticon icon font: Flaticon
  	Creation date: 17/07/2019 12:06
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-microphone:before { content: "\f100"; }
.flaticon-coffee-cup:before { content: "\f101"; }
.flaticon-chair:before { content: "\f102"; }
.flaticon-dollar-sing:before { content: "\f103"; }
.flaticon-karaoke:before { content: "\f104"; }
.flaticon-team:before { content: "\f105"; }
.flaticon-calendar:before { content: "\f106"; }
.flaticon-creative:before { content: "\f107"; }
.flaticon-network:before { content: "\f108"; }
.flaticon-laugh:before { content: "\f109"; }