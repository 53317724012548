@media screen and (max-width:784px){
    .main-div{
        width: fit-content;
        
    }
    h1.blog-heading{
        font-size: 66px;
    }
    input.search-box{
        width: 378px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .blog-card{
        height: 100%;
    }
}
