.container-i{
    background-color: #00830D;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;
    position: sticky;
    top: 0;
    opacity: 1;
}

.flex-item-i{
    color: azure;
}