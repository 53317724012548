.t-1 {
  background: -webkit-linear-gradient(96.33deg, #25a8e0 1.5%, #4936d8 150.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  text-align: center;
  margin-top: 24vh;
  margin-bottom: 4vh;
  padding: 40px;
}

/* @media (max-width:480px) {
    .t-1{
      font-size: 20px;
      padding: 20px;}
  } */

.i-1 {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
  font-size: 29px;
  line-height: 43px;
  text-align: center;
  margin-bottom: 1rem;
}

.b-1 {
  width: 75%;
  height: 80vh;
  margin: 0 auto;
  background: #c4c4c4;
  border-radius: 45px 0px;
}

.d-1 {
  padding-left: 12rem;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  text-transform: capitalize;
}

.d-2 {
  padding-right: 11rem;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  text-transform: capitalize;
  text-align: left;
}

.b-2 {
  width: 91%;
  margin: 0 auto;
  height: 28vh;
  background: linear-gradient(96.4deg, #25a8e0 0%, #4936d8 101.17%);
  border-radius: 45px 0px;
}

.notify {
  background-color: black;
  padding-bottom: 20vh;
}

.i-3 {
  width: 18rem;
  height: 108px;
  margin-left: 4vw;
  padding-top: 5.5rem;
  color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  text-align: left;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
}

.bb-1 {
  width: 23%;
  height: 4rem;
  margin-left: 65vw;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 15vh;
  margin-top: -6vh;
}

.button-info {
  margin-left: 0.6vw;
  margin-top: 0.9rem;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  cursor: pointer;
}

.in-1 {
  width: 50%;
  height: 50px;
  margin-top: 3rem;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  left: 120px;
}

.i-4 {
  width: 485px;
  height: 114px;
  margin-left: 4vw;
  padding-top: 4rem;
  color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: left;
  letter-spacing: 0.3px;
}

.bb-2 {
  width: 23%;
  height: 4rem;
  margin-left: 65vw;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 15vh;
  margin-top: -4.5vh;
}

.b-3 {
  width: 91%;
  margin: 0 auto;
  height: 35vh;
  background: linear-gradient(96.4deg, #25a8e0 0%, #4936d8 101.17%);
  border-radius: 45px 0px;
}

.p-1 {
  color: #fff;
}

.group-img {
  height: 100%;
  width: 100%;
  border-radius: 45px 0px;
}

/* .departments {
  width: 100%;
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.department {
  background: linear-gradient(
    180.26deg,
    #15141e 39.6%,
    rgba(21, 20, 30, 0) 99.78%
  );
}

.director {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dir-name {
  color: white;
}

.blue-tag {
  background: linear-gradient(96.4deg, #25a8e0 0%, #4936d8 101.17%);
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 20px;
  width: 30%;
}

.blue-tag span {
  color: white;
  text-transform: uppercase;
}

.department .text {
  /* display: flex;
  justify-content: space-between; */
  margin-top: 50px;
}

.text p {
  text-align: left;
  width: 60%;
  color: white;
}

.text img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  position: relative;
  bottom: 50px;
}

.initiatives-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.initiative-header {
  text-transform: capitalize;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initiatives {
  width: 100%;
  padding: 50px;
}

.initiative {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  color: white;
  padding: 30px 40px;
}

.initiative-desc {
  color: #c4c4c4;
  font-size: 12px;
  margin-top: 30px;
}
